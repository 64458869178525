import React from "react";
import { Route, Routes } from "react-router-dom";

import ProjectsList from "./ProjectsList";
import SingleProject from './SingleProject';
// import ErrorPage from '../../utils/ErrorPage';
import EventsProvider from "../../../context/eventsContext";

const ConfigurationRoutes = () => {
  return (
    
      <Routes>
          <Route path="/" element={<ProjectsList />} />
          
          <Route path="/:projectId" element={
            <EventsProvider>
              < SingleProject /> 
            </EventsProvider>
          } />
          
      </Routes>
  );
};

export default ConfigurationRoutes;