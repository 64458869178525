import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Input } from '@mui/material';
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import BaseAPi from '../../lib/api';
import msToTime from '../../lib/msToTime';
import parseHTML from '../../lib/htmlParser';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
export default function Dashboard(){
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    const [projects, setProjects] = useState([]);
    const [totalTimeWorked, setTotalTimeWorked] = useState(0);
    const [userType, setUserType] = useState('crew');

    const [projectInProggress, setProjectInProgress] = useState([]);

    const getDate = ( datetime ) => {
        const addZero = (n) => {
            return n > 9 ? n : `0${n}`;
        }
        let dateString = datetime.toLocaleDateString();
        
        let date = dateString.split('/');

        return `${date[2]}-${addZero(date[0])}-${addZero(date[1])}`;
    }

    useEffect(()=>{
        const today = new Date();
        const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
        setStartDate(getDate(lastWeek));
        setEndDate(getDate(today));
        // get projects that are in progress
        const fetchProjectinProgress = async () => {
            
            const getStats = await BaseAPi.get(`/dashboard/projects_in_progress`);

            BaseAPi.vResponse(getStats, response => {
                if(response.data.success){
                    setProjectInProgress(response.data.result);
                }else{
                    toast.error(response.data.message);
                }
            })
        }
        fetchProjectinProgress();
    }, []);

    useEffect(() => {
        setTotalTimeWorked(0);

        projects.forEach(project => {
            setTotalTimeWorked(previous => Number(previous) + Number(project.total_time_worked) );
        })
    },[projects]);

    useEffect(() => {
        const fetchReport = async () => {
            if(startDate === null || endDate === null){
                return;
            }
            if(startDate > endDate){
                toast.error("Start date cannot be greater than end date!");
                return;
            }

            const getStats = await BaseAPi.get(`/dashboard/stats?start_date=${startDate}&end_date=${endDate}`);

            BaseAPi.vResponse(getStats, response => {
                if(response.data.success){
                    setProjects(response.data.result.projects);
                    setUserType(response.data.result.user_type);
                }else{
                    toast.error(response.data.message);
                }
            })
        }
        fetchReport();
    }, [startDate, endDate]);

    return <>
        <h2 class="page-title">Dashboard</h2>
        <div style={{display: 'block', width: '100%', marginBottom: '40px'}}>
            Showing Report From  <Input
                                type='date'
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        to  
                            <Input
                                type='date'
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
        </div>
        {
            userType == 'crew' && projectInProggress.length > 0 &&
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h2>Work in Progress</h2>
                    </Grid>
                        {
                            projectInProggress.map((project, index) => {
                                return <Grid item xs={4} key={index}>
                                            <Card component={Link} to={`/projects/${project.project_id}`} style={{boxShadow: 'none', textDecoration: 'none'}}>
                                            <CardActionArea>
                                                <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    {project.project_title}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {parseHTML(project.project_description)}
                                                </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions>
                                                <Button size="small" color={project.status === 'working' ? 'success' : 'primary'}>
                                                    {project.status}
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                            })
                        }
                </Grid>
            </Box>
        }
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                {
                    userType == 'crew' ? 
                    <h2>Your Working Stats</h2>
                    :
                    <h2>Your Projects Stats</h2>
                }
                </Grid>
                <Grid item xs={12}>
                    <Item>Tracked Time: {msToTime(totalTimeWorked)}</Item>
                </Grid>

                <Grid item xs={12}>
                    <h2>Effected Projects!</h2>
                </Grid>
                {
                    projects.length > 0 ? 

                    
                    <>
                    {
                        projects.map((project, index) => {
                            return <Grid item xs={4}>
                                        <Card component={Link} to={`/projects/${project.project_id}`} style={{boxShadow: 'none', textDecoration: 'none'}}>
                                        <CardActionArea>
                                            <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {project.project_title}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {parseHTML(project.project_description)}
                                            </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                {msToTime(project.total_time_worked)}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                        })
                    }
                    </>
                    
                    : 
                    
                    <Grid item xs={12}>
                        <h4>Uh Oh! Looks like you don't have any project activity in the selected time frame</h4>
                    </Grid>
                }
                
                
            </Grid>
        </Box>
        
    </>
}