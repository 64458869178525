import React, { useContext, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Outlet } from "react-router-dom";
import { AuthContext } from '../context/authContext';
import { useNavigate } from "react-router-dom";
import SideBar from '../components/SideBar';


const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(7),
                },
            }),
        },
    }),
);

const mdTheme = createTheme();

const Wrapper = () => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const jwt = localStorage.getItem("jwt");
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = () => {
        setOpen(!open);
    };
    useEffect(() => {
        if (!jwt)
            navigate("/");
    })
    return (
        <>

            {user ?

                <>

                    <ThemeProvider theme={mdTheme}>
                        <Box sx={{ display: 'flex' }}>
                            <CssBaseline />
                            <Drawer variant="permanent" open={open}>
                                <Toolbar
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                        px: [1],
                                    }}
                                >
                                    {open ?
                                        <>
                                            <IconButton onClick={toggleDrawer}>
                                                <ChevronLeftIcon />
                                            </IconButton>
                                        </>
                                        :
                                        <>
                                            <IconButton onClick={toggleDrawer}>
                                                <MenuIcon />
                                            </IconButton>
                                        </>
                                    }
                                </Toolbar>

                                <SideBar />

                            </Drawer>
                            <Box
                                component="main"
                                sx={{
                                    flexGrow: 1,
                                    height: '100vh',
                                    overflow: 'auto',
                                }}
                            >
                                <Container sx={{ mt: 4, mb: 4 }}>
                                    <Grid container spacing={3} style={{ marginLeft: "20px", marginTop: '100px' }}>

                                        <Outlet />

                                    </Grid>
                                </Container>
                            </Box>
                        </Box>
                    </ThemeProvider>


                </>
                :
                <>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '100vh' }}
                    >

                        <Grid item xs={3}>
                            <CircularProgress />
                        </Grid>

                    </Grid>
                </>
            }

        </>
    )
}

export default Wrapper; 
