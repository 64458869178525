import React, { useState, useEffect, useContext } from 'react';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import toast from 'react-hot-toast';
import BaseAPi from '../../lib/api';

import { EventsContext } from '../../context/eventsContext';


export default function CommentBox ({ projectInfo }) {
    const { setEvents } = useContext(EventsContext);
    const [comment, setComment] = useState("");
    const [sessionState, setSessionState] = useState("")

    const manipulateSession = async (action_type) => {
        const request = await BaseAPi.post(`/project/session`, { project_id: projectInfo.project_id, action_type });

        BaseAPi.vResponse(request, response => {
            if(response.data.success){
                setEvents(events => [response.data.result, ...events]);
                toast.success(response.data.message);
                if(action_type == 'start')
                    setSessionState('working')
                else if(action_type == 'pause')
                    setSessionState('paused')
                else if(action_type == 'resume')
                    setSessionState('working')
                else if(action_type == 'stop')
                    setSessionState('ready');
            }else{
                toast.error(response.data.message);
            }
        })
    }

    const postComment = async() => {
        const request = await BaseAPi.post(`/project/comment`, { project_id: projectInfo.project_id, comment});

        BaseAPi.vResponse(request, response => {
            if(response.data.success){
                setEvents(events => [response.data.result, ...events]);
                toast.success(response.data.message)
                setComment("");
            }else{
                toast.error(response.data.message);
            }
        })
    }
    
    // console.log(projectInfo);

    useEffect(() => {
        if(projectInfo.last_session?.status == 'completed' ||
        projectInfo.last_session == null ||
        typeof projectInfo.last_session == undefined)
        setSessionState('ready');
        else if(projectInfo.last_session?.status == 'working')
        setSessionState('working');
        else
        setSessionState('paused');
    },[])
    return <>
    <div className="comment-box">

        <TextField
            id="outlined-textarea"
            label="Comment"
            placeholder="write comment, instruction or suggestion"
            value={comment}
            onChange={(e)=>setComment(e.target.value)}
            multiline
            rows={4}
            style={{width:"100%"}}
        />
        <Button 
            variant="outlined"
            color='secondary'
            style={{marginTop: '10px'}}
            onClick={postComment}
        >
            Post Comment
        </Button>


        {
            projectInfo.permissions.can_start_session == 1 &&
            <>
                {
                    sessionState == 'ready' ?

                    <>
                        <Button 
                            variant="contained"
                            color='secondary'
                            style={{marginTop: '10px', marginLeft: '20px'}}
                            onClick={()=>manipulateSession('start')}
                        >
                            Start Session
                        </Button>
                    
                    </>

                    :
                    
                    <>
                        {
                            sessionState == 'working' ?
                            <Button 
                                variant="contained"
                                color='primary'
                                style={{marginTop: '10px', marginLeft: '20px'}}
                                onClick={()=>manipulateSession('pause')}
                            >
                                Pause Session
                            </Button>
                            :
                            <Button 
                                variant="contained"
                                color='secondary'
                                style={{marginTop: '10px', marginLeft: '20px'}}
                                onClick={()=>manipulateSession('resume')}
                            >
                                Resume Session
                            </Button>
                        }
                        <Button 
                            variant="contained"
                            color='error'
                            style={{marginTop: '10px', marginLeft: '20px'}}
                            onClick={()=>manipulateSession('stop')}
                        >
                            Stop Session
                        </Button>
                    </>
                }
                

                
            </>
        }
        
    </div>
    </>
}