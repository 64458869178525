import axios from "axios"
import toast from 'react-hot-toast';

const BASE_URL = "https://workwith.khokon.dev";
// const BASE_URL = "http://localhost:8000";
const API_URL = `${BASE_URL}/api/v1`;


class ClassBaseAPi{
    sendRequest(type, url, args = "",headers = []){
        const jwt = localStorage.getItem('jwt'); 
    
        const build = axios.create({
            baseURL: `${API_URL}`,
            headers: {
                ...headers,
                Authorization: "Bearer "+ jwt
            }
        });
        let response = [];
        switch (type){
            case "post":
                response = build.post(url,args);
            break;
            case "get":
                response = build.get(url, args);
            break;
            default:
                response = build.get(url,args);
        };
        return response;
    }

    get(url, headers = []){
        return this.sendRequest('get', url, '', headers);
    }
    post(url, args = '', headers = []){
        return this.sendRequest('post', url, args, headers);
    }
    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }
    async vResponse (response, callback) {
        // Check if the response is okay, if so, process it with the callback
        if(response.data.success == true)
        return callback(response);
    
        // if the jwt token is expired
        if(response.data.message == 'Unauthorized Access!'){
            // try renewing the JWT
            const renewRequests = await axios.post(`${API_URL}/auth/renew_jwt`, {}, { 
                headers:{
                    Authorization: "Bearer "+ this.getCookie('refreshtoken')
                }
            });
            // failed means, the session was destroyed from server, so remove the jwt as well
            if(renewRequests.data.success == false){
                localStorage.removeItem("jwt");
                alert("Login Session Expired!");
                // window.location.reload();
            }else{
                // if success, first reset the jwt
                localStorage.setItem('jwt', renewRequests.data.result.bearer);
                // then resend the original request again
                let pre_data = "";
                console.log(response.config);
                if(typeof response.config.data !== 'undefined' && response.config.data !== null)
                    pre_data = JSON.parse(response.config.data) 
                else 
                    pre_data = [];
                const rerequest = await axios({
                    method: response.config.method,
                    url: response.config.baseURL + response.config.url,
                    headers: {
                        ...response.config.headers,
                        Authorization: "Bearer "+ renewRequests.data.result.bearer
                    },
                    data: pre_data
                });
                // proceed with the normal procedure!
                if(rerequest.data.success == true){
                    return callback(rerequest);
                }else{
                    toast.error(rerequest.data.message);
                }
            }
        }else{
            toast.error(response.data.message);
        }
    }
}
const BaseAPi = new ClassBaseAPi();


export default BaseAPi;
export { BASE_URL, API_URL };

