import React from 'react';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';

export default function ProjectList({title, description, project_id}){
    return <>
        <Card component={Link} to={`${project_id}`} className='a-project' style={{boxShadow: 'none', textDecoration: 'none'}}>
            <CardActionArea>
                <CardContent>
                    <Typography variant="h6" className='project-title'>{title}</Typography>
                    <Typography className='project-description' paragraph={true}>{
                        description.slice(0, 250)
                    }...</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    </>
}