import React, { useContext } from 'react'
import List from '@mui/material/List';
import { Link } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChatIcon from '@mui/icons-material/Chat';
import LogoutIcon from '@mui/icons-material/Logout';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { AuthContext } from '../context/authContext';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from '../lib/api';

export default function SideBar() {
    const { logout } = useContext(AuthContext);

    const processLogout = async () => {
        // const logoutRequest = await axios.post(`${API_URL}/auth/logout`);
        // console.log(logoutRequest.data);
        logout();
    }
    return <>
        <List component="nav">
            <ListItemButton component={Link} to="/app">
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>

                <ListItemText primary="Dashboard" />
            </ListItemButton>
            <ListItemButton component={Link} to="/projects">
                <ListItemIcon>
                    <AccountTreeIcon />
                </ListItemIcon>

                <ListItemText primary="Projects" />
            </ListItemButton>
            <ListItemButton component={Link} to="/chat">
                <ListItemIcon>
                    <ChatIcon />
                </ListItemIcon>

                <ListItemText primary="Chat" />
            </ListItemButton>
            {/* <ListItemButton component={Link} to="/sample">
                <ListItemIcon>
                    <CopyAllIcon />
                </ListItemIcon>

                <ListItemText primary="Sample Page" />
            </ListItemButton> */}
            <ListItemButton onClick={processLogout}>
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>

                <ListItemText primary="Logout" />
            </ListItemButton>

        </List>
    </>
}