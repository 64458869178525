import React from "react";
import { Route, Routes } from "react-router-dom";

import ChatList from "./chatList";
import ChatInbox from "./chatInbox";
const ConfigurationRoutes = () => {
  return (
    
      <Routes>
          <Route path="/" element={<ChatList />} />
          
          <Route path="/:projectId" element={
            
            < ChatInbox /> 
            
          } />
          
      </Routes>
  );
};

export default ConfigurationRoutes;