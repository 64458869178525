import React, { useState, useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import axios from "axios";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from '../utils/CopyRight';
import CircularProgress from '@mui/material/CircularProgress';
import { API_URL } from '../../lib/api';
import toast from 'react-hot-toast';
import { AuthContext } from '../../context/authContext';
import { useNavigate } from "react-router-dom";

const theme = createTheme();

export default function SignIn() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginButtonText, setLoginButtonText] = useState("Sign In");
  const navigate = useNavigate();

  const { user, login } = useContext(AuthContext);

  if(user)
  navigate("/app");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try{
      const loginRequest = await axios.post(`${API_URL}/auth/login`, {email, password});
      if(loginRequest.data.success){
        setLoginButtonText("Logged In!");
        login(loginRequest.data.result.bearer);
      }else{
        toast.error(loginRequest.data.message);
      }
      setLoading(false);
    }catch(error){
      console.log(error);
      toast.error(error.response.data.message);
      setLoading(false);
    }
    
   
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(event) => setEmail(event.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(event) => setPassword(event.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {loading ? <CircularProgress color="inherit" /> : loginButtonText }
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/forgot" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link to="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}