import React from 'react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

const parseHTML = (text) => {
    const HTML = DOMPurify.sanitize(marked.parse(text));

    return <div dangerouslySetInnerHTML={{__html: HTML}}></div>
}

export default parseHTML;