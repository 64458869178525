import React, { useState } from 'react';
import parseHTML from '../lib/htmlParser';


export default function ReadMore({ children }){
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className="read-more">
            {text.length > 150 ? 
                <>
                    {isReadMore ? parseHTML(text.slice(0, 150)) :  parseHTML(text)}
                    <span onClick={toggleReadMore} className="read-or-hide">
                        {isReadMore ? "...read more" : " show less"}
                    </span>
                </>
                :
                <>
                    { parseHTML(text)}
                </>
            }
        </p>
    );
};
