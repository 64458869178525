import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fab from '@mui/material/Fab';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import toast from 'react-hot-toast';

import BaseAPi from '../../lib/api';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function NewProjectPopup({listAddedProject, activeFilter}) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const submitTask = async () => {
    const submit = await BaseAPi.post('/project/create', {
      project_title: title,
      project_description: description
    });
    BaseAPi.vResponse(submit, response => {
      if(response.data.result.project_status == activeFilter)
        listAddedProject(response.data.result);
      toast.success('New Project Added Successfully!');
      setTitle("");
      setDescription("");
      handleClose();
    });
  }
  
  return (
    <div>
      
      <Fab 
        color="secondary" 
        variant="extended" 
        aria-label="New Project" 
        onClick={handleOpen}
        style={{
            position: "fixed",
            bottom: "40px",
            right: "40px"
        }}>
            + New Project
        </Fab>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Please Submit Your Requirement to start a new project
            </Typography>
            <TextField
                id="outlined-multiline-flexible"
                label="Project Title"
                value={title}
                onChange={(e)=>{setTitle(e.target.value)}}
                style={{ width:'100%', marginTop: '20px'}}
                placeholder="a single liner project purpose to easily remember"
            />
            <TextField
                id="outlined-multiline-static"
                label="Project Description"
                multiline
                rows={4}
                value={description}
                onChange={(e)=>{setDescription(e.target.value)}}
                style={{ width:'100%', marginTop: '20px'}}
                placeholder="a detailed overview of your project requirements"
            />
            <Button color='secondary' variant='outlined' onClick={submitTask} style={{marginTop:'20px'}}>Submit</Button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}