import React, { useState, useEffect, useContext } from 'react';
import Chip from '@mui/material/Chip';
import BaseAPi from '../../lib/api';
import toast from 'react-hot-toast';
import msToTime from '../../lib/msToTime';
import { EventsContext } from '../../context/eventsContext';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slider from '@mui/material/Slider';

export default function RenderTimeLine({events, projectInfo}){
    const { setEvents } = useContext(EventsContext);
    const [totalTimeWorked, setTotalTimeWorked] = useState(0);
    const [paid, setPaid] = useState(0);
    const [sessionId, setSessionId] = useState(0);
    const [modified, setModified] = useState(0);
    
    const [open, setOpen] = useState(false);
    

    const [editValue, setEditValue] = useState( typeof events[0].is_paid == 'undefined' ? 5000: events[0].total_time_worked );
    const [maxValue, setMaxValue] = useState( typeof events[0].is_paid == 'undefined' ? 5000: events[0].total_time_worked );

    const handleSliderChange = (event, newValue) => {
        setEditValue(newValue);
    };
    const changeValue = async () => {
        const request = await BaseAPi.post('/project/modify', { session_id: sessionId, new_value: editValue });
        BaseAPi.vResponse(request, response => {
            if(response.data.success){
                setTotalTimeWorked(editValue);
                setModified(true);
                setEvents(events => [response.data.result, ...events]);
                toast.success("Time modified!");
            }else{
                toast.error("Unable to modify work time! Something went wrong on the server!");
            }
            setOpen(false);
        });
    }
    const formatDateTime = (dateTime) => {
        const d = new Date(dateTime);
        return d.toLocaleString();
    }

    const markAsPaid = async () => {
        const request = await BaseAPi.post('/project/session/paid', { session_id: sessionId });

        BaseAPi.vResponse(request, response => {
            if(response.data.success){
                setEvents(events => [response.data.result, ...events]);
                toast.success(response.data.message)
            }else{
                toast.error(response.data.message);
            }
        })

        setPaid(cPaid => !cPaid);
    }
    
    useEffect( () => {

        const fetchSession = async () => {
            if(events[0].type != 'session') return;
            setSessionId(events[0].session_id);
            if(typeof events[0].is_paid == 'undefined'){
                const getSessionInfo = await BaseAPi.get(`/project/session/${events[0].session_id}`);
                BaseAPi.vResponse(getSessionInfo, response => {
                    if(!response.data.success){
                        toast.error(response.data.message);
                    }
                    const { total_time_worked, is_paid, is_modified} = response.data.result;
                    setTotalTimeWorked(total_time_worked);
                    setPaid(is_paid);
                    setModified(is_modified);
                    setMaxValue(totalTimeWorked);
                })
            }else{
                setTotalTimeWorked(events[0].total_time_worked);
                setPaid(events[0].is_paid);    
                setModified(events[0].is_modified);
                setMaxValue(events[0].total_time_worked);
            }
        }
        fetchSession();
    },[events]);

    return <>
        <Dialog
            open={open}
            onClose={()=>setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {msToTime(editValue)}
            </DialogTitle>
            <DialogContent>
                
                    <Slider
                        value={typeof editValue === 'number' ? editValue : 0}
                        onChange={handleSliderChange}
                        max={maxValue}
                        aria-labelledby="input-slider"
                    />
                
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpen(false)}>Cancel</Button>
                <Button onClick={changeValue} autoFocus>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
        <ul class="timeline">
            {
                events[0].type === 'session' &&
                <li class="timeline-item stat">
                    {/* <div class="timeline-marker"></div> */}
                    <div class="timeline-stats">
                        {/* <span>Session Stats: </span> */}
                        <Chip label={msToTime(totalTimeWorked)} color="secondary" variant="outlined" style={{marginRight: '20px'}}/>
                        <Chip label={paid ? 'paid' : 'unpaid'} color={paid ? 'success' : 'error'} variant="outlined" style={{marginRight: '20px'}} />
                        <Chip label={`session #${sessionId}`} color="primary" variant="outlined"  style={{marginRight: '20px'}}  />
                        {modified != false && <Chip label={`modified`} color="info" variant="outlined"  style={{marginRight: '20px'}}  /> }
                        {
                            projectInfo.permissions.can_start_session == 1 &&
                                <>
                                    <Chip 
                                        label={paid ? 'Mark as Unpaid' : 'Mark as Paid'} 
                                        color={paid ? 'warning' : 'secondary' } 
                                        clickable={true}
                                        variant="outlined" 
                                        onClick={markAsPaid}
                                        style={{marginRight: '20px'}}
                                    />
                                    <Chip 
                                        label="Modify"
                                        color="warning"
                                        clickable={true}
                                        variant="outlined" 
                                        onClick={() => setOpen(true)}
                                    />
                                </>
                        }
                    </div>
                </li>
            }
            
            {
                events.map(event => {
                    
                    return <>
                        <li class="timeline-item">
                            <div class="timeline-marker"></div>
                            <div class="timeline-content">
                                <div class="timeline-time">
                                    <span>{formatDateTime(event.createdAt)}</span>
                                </div>
                                <p>{event.event_content}</p>
                            </div>
                        </li>
                    </>
                })
            }
        </ul>
    </>
}