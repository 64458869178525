import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Auth Pages
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ForgotPassword from "./pages/auth/ForgotPassword";

// universal wrapper
import Wrapper from "./pages/Wrapper";

// utils
import ErrorPage from "./pages/utils/ErrorPage";
import SamplePage from "./pages/SamplePage";

// workspace pages
import Dashboard from "./pages/workspace/Dashboard";
import Projects from "./pages/workspace/Project";
import Chat from "./pages/workspace/Chat";


function App() {
  return (
    <Router>
      <Routes>
        {/* Auth Pages*/}
        <Route exact path="/" element={<SignIn />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/forgot" element={<ForgotPassword />} />
        

        <Route exact element={<Wrapper />}>
          <Route exact path="/app" element={<Dashboard />} />
          <Route exact path="/sample" element={<SamplePage />} />
          <Route exact path="/chat/*" element={<Chat />} />
          <Route exact path="/projects/*" element={<Projects />} />
          {/* error page  */}
        <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
