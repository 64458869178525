import { createContext, useEffect, useState } from "react";
import jwt from "jsonwebtoken";
// import { API_BASE } from "../lib/api";

export const AuthContext = createContext();

export default function AuthProvider({ children }) {
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (localStorage.getItem("jwt")) {
            setUser(jwt.decode(localStorage.getItem("jwt")));
        }
    }, []);

    const login = (token) => {
        localStorage.setItem("jwt", token);
        setUser(jwt.decode(token));
    };

    const logout = () => {
        localStorage.removeItem("jwt");
        setUser(null);
    };

    const isAuthorized = (roles) => {
        if (roles.includes(user.role)) {
            return true;
        }
        return false;
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                login,
                logout,
                isAuthorized,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
