import React, { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import '../../../styles/projects.css';
import ProjectList from '../../../components/Project/projectList';
import NewProjectPopup from '../../../components/modal/newProjectPopup';
import BaseAPi from '../../../lib/api';

export default function Projects(){
    const [activeFilter, setActiveFilter] = useState('active');
    const [projects, setProjects] = useState([]);
    const [loadMore, setLoadMore] = useState(true);
    const [loadMoreText, setLoadMoreText] = useState('Load More');
    const [page, setPage] = useState(1);

    const fetchTask = async(type = 'load', page = 1) => {
        const limit = 10;
        setLoadMoreText('loading');
        type == 'load' ? setPage(1) : setPage(cpage=>cpage+1);
        const fetchProjects = await BaseAPi.get(`/project?page=${page}&limit=${limit}&status=${activeFilter}`);
        BaseAPi.vResponse(fetchProjects, response => {
            response.data.result.length < limit && setLoadMore(false);
            type == 'load' ? 
            setProjects(response.data.result)
            :
            setProjects(projects => [...projects, ...response.data.result]);
        })
        setLoadMoreText('Load More');
    }

    const listAddedProject = project => {
        setProjects(projects => [project, ...projects]);
    }

    useEffect(()=>{
        console.log('inside useffect!');
        setLoadMore(true);
        setPage(1);
        fetchTask();
    },[activeFilter])

    return <>
        <h2 className='page-title'>Projects</h2>
        <div className="filters">
            <Chip variant={activeFilter === 'pending' ? 'contained' : 'outlined'} onClick={()=>{setActiveFilter('pending')}} label="pending"/>
            <Chip variant={activeFilter === 'active' ? 'contained' : 'outlined'} onClick={()=>{setActiveFilter('active')}} label="active"/>
            <Chip variant={activeFilter === 'completed' ? 'contained' : 'outlined'} onClick={()=>{setActiveFilter('completed')}} label="completed"/>
            <Chip variant={activeFilter === 'cancelled' ? 'contained' : 'outlined'} onClick={()=>{setActiveFilter('cancelled')}} label="cancelled"/>
        </div>
        
        <div className="projects">
            
            <Divider textAlign='left'></Divider>
            
            {
                projects.length > 0 ?

                projects.map(project => <>
                    <ProjectList project_id={project.project_id} title={project.project_title} description={project.project_description} />
                    <Divider textAlign='left'></Divider>
                </>)

                :

                <h2>
                    No Projects Found!
                </h2>

            }
        </div>
        {
            loadMore && projects.length > 0 &&
            <Box style={{
                width: '100%',
                marginTop: '20px'
            }} textAlign='center'>
                <Button variant='outlined' color='secondary' onClick={()=> { 
                    fetchTask('fetch', page+1); 
                }}>
                    {loadMoreText}
                </Button>
            </Box>
        }

        <NewProjectPopup listAddedProject={listAddedProject} activeFilter={activeFilter}/>
    </>
}