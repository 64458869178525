const msToTime = (s) => {
    if(s == 0 || s == null || typeof s == 'undefined') return '0';
    // Pad to 2 or 3 digits, default is 2
    function pad(n, z) {
        z = z || 2;
        return ('00' + n).slice(-z);
    }
    
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;
    
    if(hrs < 1 && mins < 1){
        return `${secs}s`;
    }
    if(hrs < 1){
        return `${mins}m`;
    }


    return (hrs) + 'h ' + (mins) + 'm ';
}

export default msToTime;