import React, { useState, useEffect, useContext } from 'react';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import Fab from '@mui/material/Fab';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import '../../../styles/project.css';
import ReadMore from '../../../components/readMore';
import CommentBox from '../../../components/Project/commentBox';
import EventsFeed from '../../../components/Project/eventsFeed';
import { useParams, Link } from 'react-router-dom';
import { EventsContext } from '../../../context/eventsContext';
import BaseAPi from '../../../lib/api';
import msToTime from '../../../lib/msToTime';
import toast from 'react-hot-toast';


export default function SignleProject(){
    let { projectId } = useParams();
    const [projectInfo, setProjectInfo] = useState([]);
    const [isReady, setIsReady] = useState(false);
    const [status, setStatus] = useState('pending');

    const { setEvents } = useContext(EventsContext);

    const changeStatus = async (e) => {
        const changeRequest = await BaseAPi.post(`/project/status`, {
            status: e.target.value,
            project_id: projectId
        });
        BaseAPi.vResponse(changeRequest, response => {
            if(response.data.success){
                setEvents(events => [response.data.result, ...events]);
                toast.success(response.data.message);
                setStatus(e.target.value);
            }else{
                toast.error(response.data.message);
            }
        })
    }

    const markAsPaid = async () => {
        const request = await BaseAPi.post('/project/session/paid', { all: true, project_id: projectInfo.project_id });

        BaseAPi.vResponse(request, response => {
            if(response.data.success){
                setEvents(events => [response.data.result, ...events]);
                toast.success(response.data.message)
            }else{
                toast.error(response.data.message);
            }
        })
    }

    useEffect(()=>{
        const fetchProjectInfo = async () => {
            const getInfo = await BaseAPi.get(`/project/${projectId}`);
            BaseAPi.vResponse(getInfo, response => {
                // console.log(response);
                setProjectInfo(response.data.result);
                setStatus(response.data.result.project_status);
                setIsReady(true);
                // console.log(projectInfo);
            })
        }
        fetchProjectInfo();
    },[]);

    return <>
        {
        !isReady ?
        <>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}
            >

                <Grid item xs={3}>
                    <CircularProgress />
                </Grid>

            </Grid>
        </>
        :
        <>
            <h2 className='page-title'>Project: {projectInfo.project_title}</h2>
            <ReadMore>
                {projectInfo.project_description}
            </ReadMore>
            <div className="peoples">
                {/* <span className='span'><i>people:</i></span> */}
                {
                    projectInfo.peoples.map(people => <><Chip avatar={<Avatar>{people.user.first_name.charAt(0)}</Avatar>} label={`${people.user.first_name} ${people.user.last_name}`} /></>)
                }
                {
                    projectInfo.permissions.can_add_member == 1 &&
                    <Chip variant="outlined" color="secondary" label="+" onClick={()=>{ toast.error('This UI is comming soon!') }} />
                }
            </div>
            <div className="quick-overview">
                {/* <span className='span'><i>overview:</i></span> */}
                <Chip variant="outlined" color="error" label={`unpaid ${msToTime(projectInfo.unpaid_hours)}`} />

                {
                    projectInfo.permissions.can_start_session == 1 &&
                    <>
                        <Chip variant="outlined" color="success" label={`paid ${msToTime(projectInfo.paid_hours)}`} />
                        <Chip variant="outlined" color="info" label={`total ${msToTime(projectInfo.total_hours)}`} />
                        <Chip variant="outlined" color="warning" label={`mark as paid`} onClick={markAsPaid} />
                        <FormControl size="small">
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                displayEmpty
                                value={status}
                                onChange={changeStatus}
                                style={{
                                    borderRadius: '16px', 
                                    height: '32px',
                                    fontSize: '0.8125rem'
                                }}
                                >
                                <MenuItem value='pending'>pending</MenuItem>
                                <MenuItem value='active'>active</MenuItem>
                                <MenuItem value='completed'>completed</MenuItem>
                                <MenuItem value='cancelled'>cancelled</MenuItem>
                            </Select>
                        </FormControl>
                    </>
                }


            </div>
            {
                projectInfo.permissions.can_comment == 1 &&
                
                    <CommentBox projectInfo={projectInfo} />


            }

                <EventsFeed projectInfo={projectInfo} project_id={projectInfo.project_id} />
            {
                projectInfo.permissions.can_chat == 1 &&
                <Fab 
                // color="secondary" 
                // variant="extended" 
                aria-label="New Project" 
                component={Link}
                to={`/chat/${projectInfo.project_id}`}
                style={{
                    position: "fixed",
                    bottom: "40px",
                    right: "40px"
                }}>
                    <ChatBubbleIcon />
                </Fab>
            }
            </>
        }
    </>
}