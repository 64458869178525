import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import RenderTimeLine from './renderTimeline';
import BaseAPi from '../../lib/api';
import { EventsContext } from '../../context/eventsContext';

export default function EventsFeed({ project_id, projectInfo }){
    // const [events, setEvents] = useState([]);
    const { events, setEvents } = useContext(EventsContext);
    const [loadMore, setLoadMore] = useState(true);
    const [loadMoreText, setLoadMoreText] = useState('Load More');
    const [page, setPage] = useState(1);

    const [isReady, setIsReady] = useState(false);

    const [timelines, setTimeLines] = useState([]);
    

    const fetchTask = async(type = 'load', page = 1) => {
        const limit = 30;
        setLoadMoreText("Loading...");
        type == 'load' ? setPage(1) : setPage(cpage=>cpage+1);
        const fetchEvents = await BaseAPi.get(`/project/events?page=${page}&limit=${limit}&project_id=${project_id}`);
        BaseAPi.vResponse(fetchEvents, response => {
            response.data.result.length < limit && setLoadMore(false);
            type == 'load' ? 
            setEvents(response.data.result)
            :
            setEvents(events => [...events, ...response.data.result]);
        })
        setLoadMoreText("Load More");
        setIsReady(true);
    }

    useEffect(()=>{
        let singleTimeLine = [];
        let prepareTimeLine = [];
        let lastAdded = 'ready';
        events.forEach((event, index, arr) => {
            if(lastAdded != 'ready' && lastAdded != event.session_id || lastAdded == null){
                prepareTimeLine.push(singleTimeLine);
                lastAdded = 'ready';
                singleTimeLine = [];
            }
            lastAdded = event.session_id;
            singleTimeLine.push(event)
            if(arr.length - 1 == index){
                prepareTimeLine.push(singleTimeLine);
                setTimeLines(prepareTimeLine);    
            }
        });
    },[events]);
    useEffect(()=>{
        console.log('inside useffect!');
        setLoadMore(true);
        setPage(1);
        fetchTask();
    },[])

    return <>
    <div className="section-divider">
        <Divider textAlign='left'>
            Project Events
        </Divider>
    </div>
    {
        !isReady ?
        <>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}
            >

                <Grid item xs={3}>
                    <CircularProgress />
                </Grid>

            </Grid>
        </>
        :
        <>
            {
                timelines.length > 0 ?

                timelines.map(timeline => <RenderTimeLine projectInfo={projectInfo} events={timeline} />)

                :

                <h2>
                    Nothing yet! Start a session or write a comment to start seeing activities!
                </h2>

            }
            
            {
                loadMore && events.length > 0 &&
                <Box style={{
                    width: '100%',
                    marginTop: '20px'
                }} textAlign='center'>
                    <Button variant='outlined' color='secondary' onClick={()=> { 
                        fetchTask('fetch', page+1); 
                    }}>
                        {loadMoreText}
                    </Button>
                </Box>
            }
        
        </>
    }
        
    </>
}